<template>
  <div class="collapse-box">
    <div
      data-target="#artist-songs"
      class="collapse-heading"
      data-toggle="collapse"
      @click="toggleAccordion"
    >
      <h2>Músicas</h2>
    </div>
    <div
      id="artist-songs"
      class="collapse collapse-body"
    >
      <table class="table table-dark">
        <thead>
          <tr>
            <th width="30">
              ID
            </th>
            <th>
              Música
            </th>
          </tr>
        </thead>
        <tr
          v-for="song in songs"
          :key="song.id_musica"
        >
          <td>{{ song.id_musica }}</td>
          <td>
            <div class="d-flex">
              <router-link :to="{ name: 'songShow', params: {id: song.id_musica} }">
                {{ song.titulo }}
              </router-link>
              <div class="ml-5 hover-menu">
                <a href="javascript:void(0)" class="text-secondary" @click="openDeleteSongModal(song)">
                  <i class="fa fa-trash"></i> excluir
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <div class="text-center">
        <button-next-page @reached="fetchSongs"></button-next-page>
      </div>
    </div>
  </div>
</template>

<script>
  import { getArtistSongs } from '@/api/artists'
  import ButtonNextPage from '@/components/utils/ButtonNextPage'
  import SongDeleteModal from '@/components/modals/songs/SongDeleteModal'

  export default {
    components: {
      ButtonNextPage
    },
    data: () => ({
      page: 1,
      songs: []
    }),
    methods: {
      toggleAccordion() {
        if (this.page !== 1) return null
        this.fetchSongs()
      },
      async fetchSongs() {
        const songs = await getArtistSongs(this.$route.params.id, {
          page: this.page++
        })

        songs.map(s => this.songs.push(s))
      },
      openDeleteSongModal (song) {
        this.$modal.show(SongDeleteModal, {song}, {height: 'auto'})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hover-menu {
    display: none;
  }

  td:hover .hover-menu {
    display: block;
  }
</style>
<template>
  <div class="page-with-title">
    <page-title>
      <div class="d-flex align-items-center">
        <div style="position: relative">
          <img
            ref="avatar"
            :src="artist.avatar"
            width="75"
            height="75"
          >

          <UploadButton
            :fullmode="true"
            @change="$e => uploadImage($e)"
          />
        </div>

        <div class="ml-3">
          <h1 v-if="isEditing">
            Artista: {{ newData.artista }}
          </h1>

          <h1 v-else>
            Novo artista: {{ newData.artista }}
          </h1>

          <a
            v-if="isEditing"
            :href="artist.url"
            target="_blank"
          >{{ artist.url }}</a>
        </div>
        <div
          v-if="isEditing"
          class="ml-4 dropdown d-none"
        >
          <button data-toggle="dropdown" class="btn btn-sm btn-secondary">
            <i class="fa fa-cog" />
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">teste</a>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item text-secondary"
              href="#"
              @click="destroy"
            >excluir artista</a>
          </div>
        </div>
      </div>
    </page-title>

    <div
      v-if="isReady"
      class="p-4 mt-4"
    >
      <div class="row">
        <div class="col-4">
          <div
            :class="{'has-error': $v.newData.artista.$dirty && $v.newData.artista.$invalid}"
            class="form-group"
          >
            <label for="artista">Nome</label>
            <input
              v-model="newData.artista"
              type="text"
              class="form-control"
              @click="$v.newData.artista.$touch()"
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label for="artista">Gênero</label>
            <select
              v-model="newData.id_categoria"
              class="form-control"
            >
              <option value="0">
                Sem gênero
              </option>
              <option
                v-for="genre in genres"
                :key="genre.id_estilo"
                :value="genre.id_estilo"
              >
                {{ genre.nome }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <hr>

      <ArtistBiographyAccordion
        :biography="newData.biografia"
        class="mb-3"
        @changed="v => newData.biografia = v"
      />

      <ArtistPicturesAccordion
        class="mb-3"
        v-if="isEditing"
      />

      <ArtistAlbumsAccordion
        :artist="this.artist"
        class="mb-3"
        v-if="isEditing"
      />

      <ArtistSongsAccordion
        v-if="isEditing"
      />

      <div class="mt-4 mb-3">
        <button
          :disabled="$v.$invalid"
          class="btn btn-2 btn-bigger mr-3"
          @click="submit"
        >
          <b><i class="fa fa-check" /> Salvar</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { getArtist, updateArtist, storeArtist } from '@/api/artists'
  import { getGenres } from '@/api/genres'
  import PageTitle from '@/components/titles/PageTitle'
  import UploadButton from '@/components/utils/UploadButton'
  import ArtistSongsAccordion from '@/components/artists/ArtistSongsAccordion'
  import ArtistBiographyAccordion from '@/components/artists/ArtistBiographyAccordion'
  import ArtistPicturesAccordion from '@/components/artists/ArtistPicturesAccordion'
  import { validationMixin } from 'vuelidate'
  import artistValidation from '@/validations/artistValidation'
  import ArtistAlbumsAccordion from '@/components/artists/ArtistAlbumsAccordion'

  export default {
    components: {
      PageTitle,
      UploadButton,
      ArtistSongsAccordion,
      ArtistBiographyAccordion,
      ArtistPicturesAccordion,
      ArtistAlbumsAccordion
    },

    mixins: [validationMixin],

    validations: artistValidation,

    data: () => ({
      artist: {
        avatar: 'https://www.letras.com.br/img/sem-imagem.png'
      },

      genres: [],

      newData: {
        artista: '',
        id_categoria: '0',
        biografia: ''
      },

      isReady: false
    }),

    computed: {
      isEditing () {
        return typeof this.id !== 'undefined'
      },

      id () {
        return this.$route.params.id
      }
    },

    watch: {
      artist(artist) {
        if (artist.genre) {
          this.newData.id_categoria = artist.genre.id_estilo
        }

        if (artist.biography) {
          this.newData.biografia = artist.biography.biografia
        }

        this.newData.artista = artist.artista
      }
    },

    async mounted() {
      this.$store.commit('app/SET_LOADING', true)
      await this.fetchGenres()
      if (this.isEditing) {
        await this.fetchArtist()
      }
      this.isReady = true
      this.$store.commit('app/SET_LOADING', false)
    },
    
    methods: {
      async fetchArtist() {
        this.artist = await getArtist(this.$route.params.id)
      },
      async fetchGenres() {
        this.genres = await getGenres()
      },
      uploadImage($e) {
        const file = $e.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
          this.$refs.avatar.src = reader.result
          this.newData.avatar = file
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      },
      isSubmitAvailable() {
        return Object.entries(this.newData).length !== 0
      },
      async submit() {
        if (this.isEditing) {
          updateArtist(this.$route.params.id, this.newData)
        } else {
          const artist = await storeArtist(this.newData)
          this.$router.push({
            name: 'artistShow',
            params: {id: artist.cod_artista}
          })
        }
      },
      destroy() {
        if (window.confirm("ATENÇÃO !!!\n\nTem certeza que deseja EXCLUIR PERMANENTEMENTE esse artista?\n\nTodas informações sobre ele serão deletadas também como músicas, imagens, álbuns, ...")) {
          alert('OK!')
        }
      }
    }
  }
</script>
<template>
  <router-link :to="albumEditRoute">
    <div class="embed-responsive embed-responsive-1by1">
        <img :src="album.avatar" class="embed-responsive-item">
    </div>
    
    <span>{{ album.titulo }} <span v-if="album.ano">- {{ album.ano }}</span></span>
  </router-link>
</template>

<script>
  export default {
    props: {
      album: { type: Object, default: () => ({}) }
    },

    computed: {
      albumEditRoute () {
        return {
          name: 'album',
          params: { id: this.album.id_album }
        }
      },
    }
  }
</script>
import request from '@/utils/request'

export async function getAlbumsByArtistId(artistId) {
  const res = await request({
    url: `/artists/${artistId}/albums`,
    method: 'get'
  })

  return res.data.data
}

export async function getAlbumById(id) {
  const res = await request({
    url: `/albums/${id}`,
    method: 'get'
  })

  return res.data.data
}

export async function storeAlbum (data) {
  data = Object.assign({}, data)
  delete data['avatar_file']

  const res = await request({
    url: `/albums`,
    method: 'post',
    data
  })

  return res.data.data
}

export async function updateAlbumAvatar (id, file) {
  const form = new FormData()
  form.append('avatar_file', file)
  form.append('_method', 'put')

  const res = await request({
    url: `/albums/${id}`,
    method: 'post',
    data: form
  })

  return res.data.data
}

export async function updateAlbum(id, data) {
  data = Object.assign({ _method: 'put' }, data)

  await request({
    url: `/albums/${id}`,
    method: 'post',
    data
  })
}

export async function deleteAlbum (id) {
  await request({
    url: `/albums/${id}`,
    method: 'delete',
  })
}

export async function getAlbumSongs (id) {
  const res = await request({
    url: `/albums/${id}/songs`,
    method: 'get',
  })

  return res.data.data
}

export async function updateAlbumSongs (id, songs) {
  const res = await request({
    url: `/albums/${id}/songs`,
    method: 'post',
    data: {
      _method: 'put',
      songs
    }
  })

  return res.data.data
}

export async function deleteAlbumSong (id) {
  await request({
    url: `/album-songs/${id}`,
    method: 'delete'
  })
}
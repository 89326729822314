import request from '@/utils/request'

export async function getSongLyrics(id) {
  const res = await request({
    url: `/songs/${id}/lyrics`,
    method: 'get'
  })

  return res.data.data
}

export async function deleteLyrics(id) {
  const res = await request({
    url: `/lyrics/${id}`,
    method: 'delete'
  })

  return res.data
}
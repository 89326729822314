<template>
  <div class="collapse-box">
    <div data-target="#artist-pictures" class="collapse-heading" data-toggle="collapse">
      <h2>Fotos</h2>
    </div>

    <div id="artist-pictures" class="collapse collapse-body">

    <div>
      <UploadButton
        :multiple="true"
        @change="upload"
      >Carregar imagens</UploadButton>
      <hr class="mt-3 mb-3" />
    </div>

    <ArtistPictureList
      :pictures="artistPictures"
    />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ArtistPictureList from '@/components/artists/ArtistPictureList'
  import UploadButton from '@/components/utils/UploadButton'

  export default {
    components: {
      ArtistPictureList,
      UploadButton,
    },

    async mounted () {
      await this.fetchArtistPictures(this.id)
    },

    computed: {
      ...mapGetters('artist', ['artistPictures']),

      id () {
        return this.$route.params.id
      }
    },

    methods: {
      ...mapActions('artist', [
        'fetchArtistPictures',
        'uploadArtistPictures',
      ]),

      async upload ($event) {
        await this.uploadArtistPictures({
          id: this.id,
          pictures: $event.target.files
        })
      }
    }
  }
</script>

<template>
  <div>
    <div class="m-header">Exclusão da música <u>{{ song.titulo }}</u></div>
    <div class="m-body">
      <div class="mb-3">
        <strong>Depois de feita a ação não é possível recuperar.</strong>
      </div>
      <button
        v-for="lyrics in songLyrics"
        :key="lyrics.id_letra"
        class="btn-1 btn mr-3"
        @click="deleteLyrics(lyrics)"
      >
        <i class="fa fa-close"></i>
        <span v-if="lyrics.cod_tipo != '0'"> {{ lyrics.legend }}</span>
        <span v-if="lyrics.cod_tipo == '0'"> Excluir música</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { deleteSong } from '@/api/songs'
  import {
    getSongLyrics,
    deleteLyrics
  } from '@/api/lyrics'

  export default {
    props: ['song'],
    data: () => ({
      songLyrics: []
    }),
    mounted () {
      this.fetchSongLyrics()
    },
    methods: {
      async fetchSongLyrics () {
        this.songLyrics = await getSongLyrics(this.song.id_musica)
      },
      async deleteLyrics (lyrics) {
        if (lyrics.cod_tipo == '0') {
          await deleteSong(this.song.id_musica)
        } else {
          await deleteLyrics(lyrics.id_letra)
        }
        this.$emit('close')
        window.location.reload()
      }
    }
  }
</script>

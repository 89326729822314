<template>
    <div class="collapse-box">
        <div data-target="#albums" class="collapse-heading" data-toggle="collapse" >
            <h2>Álbuns</h2>
        </div>

        <div id="albums" class="collapse collapse-body" >
            <router-link :to="addNewAlbumRoute" class="btn btn-secondary">
                <i class="fa fa-plus" />
                Criar álbum
            </router-link>

            <hr class="mt-3 mb-4" />

            <AlbumList
              :albums="albums"
            />
        </div>
    </div>
</template>

<script>
  import AlbumList from '@/components/albums/AlbumList'
  import {
    getAlbumsByArtistId
  } from '@/api/albums'

  export default {
      components: {
          AlbumList,
      },

      props: {
        artist: { type: Object, default: () => ({}) }
      },

      data: () => ({
        albums: []
      }),

      mounted () {
        this.fetchAlbums()
      },

      computed: {
        addNewAlbumRoute () {
          return {
            name: 'album',
            params: {
              id: 'novo',
              artist: this.artist
            },
          }
        }
      },

      methods: {
        async fetchAlbums() {
          this.albums = await getAlbumsByArtistId(this.artist.cod_artista)
        }
      }
  }
</script>

<template>
  <div class="row">
    <div
      v-for="album in albums"
      :key="album.id_album"
      class="col-3 mb-3"
    >
      <Album :album="album" />
    </div>
  </div>
</template>

<script>
  import Album from '@/components/albums/Album'

  export default {
    components: {
      Album,
    },

    props: {
      albums: { type: Array, default: () => ([]) }
    }    
  }
</script>
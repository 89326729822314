<template>
  <div class="collapse-box">
    <div
      :data-target="`#lyrics-1`"
      class="collapse-heading"
      data-toggle="collapse"
    >
      <h2>Biografia</h2>
    </div>
    <div
      :id="`lyrics-1`"
      class="collapse collapse-body"
    >
      <textarea
        v-model="bio"
        class="form-control" 
        rows="10"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      biography: {
        default: ''
      }
    },
    data: () => ({
      bio: ''
    }),
    mounted() {
      this.bio = this.biography
    },
    watch: {
      bio(val) {
        this.$emit('changed', val)
      }
    }
  }
</script>
<template>
  <div class="pictures">
    <ArtistPictureManager
      v-for="picture in pictures"
      :key="picture.id_foto_artista"
      :picture="picture"
    />
  </div>
</template>

<script>
  import ArtistPictureManager from '@/components/artists/ArtistPictureManager'

  export default {
    props: {
      pictures: { type: Array, default: () => [] }
    },

    components: {
      ArtistPictureManager,
    }
  }
</script>

<style scoped lang="scss">
  .pictures {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    & > * {
      padding: 5px;
    }
  }
</style>
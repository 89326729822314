<template>
  <div
    class="picture"
  >
    <div class="picture__inner">
      <picture class="picture__picture">
        <img
          class="picture__picture__img"
          :src="picture.url"
        />
      </picture>

      <div class="picture__options">
        <button
          class="picture__options__btn"
          @click="destroy"
        >
          <i class="fa fa-trash"></i> Excluir
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      picture: { type: Object, default: () => ({}) }
    },

    computed: {
      id () {
        return this.picture.id_foto_artista
      }
    },

    methods: {
      ...mapActions('artist', ['destroyArtistPicture']),

      destroy () {
        if (!window.confirm('Tem certeza que deseja EXCLUIR PERMANENTEMENTE esta imagem?')) {
          return null;
        }

        this.destroyArtistPicture(this.id)
      }
    }
  }
</script>

<style scoped lang="scss">
  .picture {
    width: 25%;

    &__inner {
      background-color: lighten(#000, 35%);
      padding: 15px;
      border-radius: 5px;
    }

    &__picture {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      overflow: hidden;

      &__img {
        max-width: 100%;
        width: auto;
      }
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      &__btn {
        color: lighten(#ff0000, 35%);
      }
    }
  }
</style>